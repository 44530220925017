* { -moz-box-sizing: border-box; -webkit-box-sizing: border-box; box-sizing: border-box; }

* {
    padding: 0;
    margin: 0;
    position: relative;
}

html, body {
  margin: 0;
  padding: 0;
  height: 100%;
  width: 100%;
  overflow: hidden;
}

body {
  background-color: #5E3933;
  background: url('./Painting/background.jpeg'), url('./layers/backgrounds/slightly-overripe-loop.gif');
  background-size: cover, cover;
  background-repeat: no-repeat, no-repeat;
  background-blend-mode: multiply;
}

.Painting {
  position: relative;
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
}
