.Layer {
    width: 100vw;
    height: 100vh;

    background-size: 100%;
    background-repeat: no-repeat;

    z-index: 1;

    overflow: hidden;
    animation-duration: 2s;
}

@media screen and (min-width: 700px) {
    .Layer {
        background-size: contain;
    }
}

.Layer.breath {
    animation-name: breath;
    animation-iteration-count: infinite;
}

@keyframes breath {
    0% {
        transform: scale(1)
    }
    40%, 60% {
        transform: scale(0.96)
    }
    100% {
        transform: scale(1)
    }
}